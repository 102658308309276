body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.table-content,
.content-header,
.content-body {
  border: 1px solid black;
  border-collapse: collapse;
  padding-left: 5px;
}

.align-center {
  text-align: center;
}

.hour {
  font-size: 30px;
}

.hour-second {
  font-size: 16px;
}

.header-size {
  width: 85%;
}

.yellow-card {
  margin-left: 5px;
}

.content-header-line {
  width: 3%;
}

.content-header-order {
  width: 20%;
}

.content-header-eta {
  width: 10%;
}

.content-header-client {
  width: 20%;
}

.content-header-other {
  width: 5%;
}

.navbar-brand {
  position: absolute;
  left: 50%;
  margin-left: -75px !important; /* 50% of your logo width */
  display: block;
}

.warning {
  margin-right: 10px;
}

.detail-title {
  font-size: 18px;
}

.info-content {
  margin-top: 75px;
  margin-bottom: 30px;
  border: solid 1px;
}

.empty-text {
  margin-top: 20%;
  font-size: 30px;
  font-weight: bold;
}

@media screen and (max-width: 800px) {
  .empty-text {
    margin-top: 50%;
    font-size: 30px;
    font-weight: bold;
  }
  .hour-second {
    font-size: 14px;
  }
  .detail-title {
    font-size: 13px;
  }
  .badge-content {
    font-size: 7px;
  }
  .badge-total-content {
    margin-left: 10px;
    font-size: 10px;
  }
  .hour {
    font-size: 25px;
  }
  .card-text {
    font-size: 14px;
  }
}

@media screen and (max-width: 3000px) {
  .table-content {
    display: none;
  }
  .header-content {
    display: none;
  }
}

@media print {
  @page {
    size: landscape;
  }

  .hideOnPrint {
    display: none;
  }
  .table-content,
  .content-header,
  .content-body {
    font-size: 10px;
  }
  .hour {
    font-size: 15px;
  }
  .hour-second {
    font-size: 10px;
  }
  .header-content {
    font-size: 10px;
  }
  .navbar {
    position: relative;
  }
}
